import React from "react";
import { Provider, useSelector } from "react-redux";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { createStore, combineReducers } from "redux";
import {
  ReactReduxFirebaseProvider,
  firebaseReducer,
  isLoaded,
} from "react-redux-firebase";
import { createFirestoreInstance, firestoreReducer } from "redux-firestore";

import styled, { ThemeProvider } from "styled-components";
import { MuiThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { SnackbarProvider } from "notistack";

import themeMyPlayer from "./config/theme";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Home from "./pages/Home";
import AuthPage from "./pages/Auth";

import { ROUTES } from "./routes/routes";
import { firebaseConfig } from "./config/firebase";
import { Spinner } from "./components";
import PrivateRoute from "./routes/PrivateRoute";

// react-redux-firebase config
const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
};

export const Firebase = firebase.initializeApp(firebaseConfig);
export const Auth = firebase.auth();

export const DefaultPage = <Redirect to={{ pathname: ROUTES.login }} />;

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
});

// Create store with reducers and initial state
const initialState = {};
const store =
  process.env.NODE_ENV === "production"
    ? createStore(rootReducer, initialState)
    : createStore(
        rootReducer,
        initialState,
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
      );

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

const AuthIsLoaded = ({ children }) => {
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth)) return <Spinner />;
  return children;
};

const Container = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const App = () => {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <MuiThemeProvider theme={themeMyPlayer}>
          <ThemeProvider theme={themeMyPlayer}>
            <SnackbarProvider
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              autoHideDuration={3000}
            >
              <Container>
                <Router>
                  <AuthIsLoaded>
                    <StylesProvider injectFirst>
                      <Switch>
                        <Route path={ROUTES.auth}>
                          <AuthPage />
                        </Route>
                        <Route path={ROUTES.login}>
                          <Login />
                        </Route>
                        <Route path={ROUTES.signup}>
                          <SignUp />
                        </Route>
                        <PrivateRoute exact path={ROUTES.home}>
                          <Home />
                        </PrivateRoute>
                        <Route path={ROUTES.no_match}>{DefaultPage}</Route>
                      </Switch>
                    </StylesProvider>
                  </AuthIsLoaded>
                </Router>
              </Container>
            </SnackbarProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
};

export default App;
